import Vue from 'vue';
import VueRouter from 'vue-router';
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/bind',
    },
    {
      path: '/bind',
      name: 'bind',
      meta: { title: '绑定宝贝', icon: '' },
      component: () => import('../views/bind/bind.vue'),
    },
    {
      path: '/home',
      name: 'home',
      meta: { title: '主页', icon: '' },
      component: () => import('../views/home/home.vue'),
    },
    {
      path: '/childInfo',
      name: 'childInfo',
      meta: { title: '宝贝信息', icon: '' },
      component: () => import('../views/childInfo/index.vue'),
    },
    {
      path: '/changeChild',
      name: 'changeChild',
      meta: { title: '切换宝贝', icon: '' },
      component: () => import('../views/childInfo/changeChild.vue'),
    },
    {
      path: '/childInfoEdit',
      name: 'childInfoEdit',
      meta: { title: '修改宝贝信息', icon: '' },
      component: () => import('../views/childInfo/childInfoEdit.vue'),
    },
    {
      path: '/report',
      name: 'report',
      meta: { title: '查看报告', icon: '' },
      component: () => import('../views/report/report.vue'),
    },
    {
      path: '/plan',
      name: 'plan',
      meta: { title: '提升方案', icon: '' },
      component: () => import('../views/plan/plan.vue'),
    },
    {
      path: '/detail',
      name: 'detail',
      meta: { title: '文章详情', icon: '' },
      component: () => import('../views/detail/index.vue'),
    },
    {
      path: '/video',
      name: 'video',
      meta: { title: '视频列表', icon: '' },
      component: () => import('../views/video/index.vue'),
    },
    {
      path: '/videoDetail',
      name: 'videoDetail',
      meta: { title: '视屏详情', icon: '' },
      component: () => import('../views/videoDetail/index.vue'),
    },
    {
      path: '/clear',
      name: 'clear',
      meta: { title: '清楚缓存', icon: '' },
      component: () => import('../views/clear/clear.vue'),
    },
    {
      path: '/reportApp',
      name: 'reportApp',
      meta: { title: '体测报告', icon: '' },
      component: () => import('../views/report/reportApp.vue'),
    },

  ],
});
