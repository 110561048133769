<template>
  <div id="app">
    <router-view class="main"></router-view>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import api from './common/js/api';
import config from './common/js/config';
import {clearToken, saveToken} from './common/js/cache';
import {Toast} from 'vant';

export default {
  components: {},
  data() {
    return {
      version: new Date().getTime(),
    };
  },
  created() {
  },
  mounted() {
    clearToken();
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') !== -1; // 是否 在微信浏览   器内
    let isURL = window.location.href.indexOf('token=') === -1; // 是否 没有授权重定向回来
    // 重定向回来
    if (!isURL) {
      var urlToken = window.location.href.split('?token=');
      saveToken(urlToken[1]);
      Toast.clear();
      if (isWeixin) {
        this.getWxConfig();
      }
    }
    //在微信中;
    if (isWeixin && isURL) {
      this.getLogin();
    } else {
      //在pc端
    }
  },
  watch: {},
  methods: {
    getWxConfig() {
      let urlNow = encodeURIComponent(window.location.href.split('#')[0]);
      this.$post(api.wxConfig, {url: urlNow}).then((res) => {
        wx.config({
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          debug: false,
          // 必填，公众号的唯一标识
          appId: res.data.appId,
          // 必填，生成签名的时间戳
          timestamp: res.data.timestamp,
          // 必填，生成签名的随机串
          nonceStr: res.data.nonceStr,
          // 必填，签名
          signature: res.data.signature,
          // 必填，需要使用的JS接口列表，所有JS接口列表
          jsApiList: ['scanQRCode'],
        });
      });
    },
    getLogin() {
      let url = config.Host_Gong + 'public/wxLogin'; // 生产
      let str =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          config.appId +
          '&redirect_uri=' +
          encodeURIComponent(url) +
          `&response_type=code&scope=snsapi_userinfo&state=2&version=${this.version}#wechat_redirect`;
      window.location.replace(str);
    },
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  // font-size: 0.32rem;
  background: #eeeff3;
}

#app {
  font-family: Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #eeeff3;
  // font-size: 0.32rem;
  height: 100%;
}

.main {
  height: 100%;
}
</style>
