export default {
  getReportOld: '/kg/wx/report/phyResult', //获取报告机构
  getReport23: '/test/report23/gzhPhyResult_allow', //获取报告新国标
  getReport: '/test/report/gzhPhyResult_allow', //获取报告老国标
  wxConfig: '/public/getConfigInfo', //微信配置，用来做扫码功能
  getStuList: '/public/bindStuList', //获取学生列表
  bindStu: '/public/bindStudents', //绑定学生
  videoService: '/kg/wx/video/improve', //获取视频
  pictureService: '/kg/wx/text/view', //获取图文
  unbindService: '/wx/parents/unbind', //解绑学员
  childInfoService: '/wx/parents/stuInfo', //查询学员明细
  editInfoService: '/wx/parents/stuEdit', //编辑学员信息
  planList:'/test/plan/list_allow',//获取计划列表
};
