import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import { Picker } from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import 'vant/lib/index.css';
import { post, get, put, deleteMethod ,postSan} from './common/js/utils';


// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// export default vConsole


Vue.use(Vant);
Vue.use(Picker);
Vue.config.productionTip = false;
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$post = post;
Vue.prototype.$postSan = postSan;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$delete = deleteMethod;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (from.name === 'childInfo' && to.name === 'bind') {
    to.query.type = 1;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
