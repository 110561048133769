import storage from 'good-storage';

const TOKEN_KEY = '__token__';
const TOKEN_KEY_2 = '__token__2';
// 保存token
export function saveToken(data) {
  storage.set(TOKEN_KEY, data);
}

// 获取token
export function getToken() {
  const token = storage.get(TOKEN_KEY);
  // const token="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJvWkZaQzZEakZoYzBhanlkU01qemRGTkFyZTNVIiwiZXhwIjoxNjMwNzIyMDg4fQ.02GaN73AGWQohv6eAa3l6zTWfBgvviUjfb67xGML81xa6Us5BP612TNz9PxJwCkc0EudanhGGD0_HpmOFlmRTA";
  return token;
}

//清除token
export function clearToken() {
  storage.remove(TOKEN_KEY);
}


// 保存token2
export function saveToken2(data) {
  storage.set(TOKEN_KEY_2, data);
}

export function getToken2() {
  const token = storage.get(TOKEN_KEY_2);
  return token;
}