import axios from "./axios";
import config from "./config";

/**
 * axios封装post请求
 * @url url
 * @params 参数
 */
function postSan(url, params = {}) {
  axios.defaults.baseURL = config.Host;
  return new Promise((resolve) => {
    axios.post(url, params).then((res) => {
      if (res) resolve(res.data || res);
    });
  });
}

/**
 * axios封装post请求
 * @url url
 * @params 参数
 */
function post(url, params = {}) {
  axios.defaults.baseURL = config.Host_Gong;
  return new Promise((resolve) => {
    axios.post(url, params).then((res) => {
      if (res) resolve(res.data || res);
    });
  });
}


/**
 * axios封装post请求
 * @url url
 * @params 参数
 */
function downloadPost(url, params = {}) {
  return axios({
    url: url,
    method: "post",
    data: params,
    responseType: "blob",
  });
}

/**
 * axios封装get请求
 * @url url
 * @params 参数
 */
function get(url, params = {}) {
  return new Promise((resolve) => {
    axios.get(url, params).then((res) => {
      if (res) resolve(res.data || res);
    });
  });
}

/**
 * axios封装put请求
 * @url url
 * @params 参数
 */
function put(url, params = {}) {
  return new Promise((resolve) => {
    axios.put(url, params).then((res) => {
      if (res) resolve(res.data || res);
    });
  });
}

/**
 * axios封装delete请求
 * @url url
 * @params 参数
 */
function deleteMethod(url, params = {}) {
  return new Promise((resolve) => {
    axios.delete(url, params).then((res) => {
      if (res) resolve(res.data || res);
    });
  });
}

export { post, get, put, deleteMethod, downloadPost,postSan };
