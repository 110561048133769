export default {
    //APP
    //测试环境
    //Host: 'http://192.168.1.51:80/', //liu本地环境
    //线上环境
    Host: 'https://api.jinxikids.com/newversion/',

    //公众号
    //测试环境
    //Host_Gong: 'http://192.168.1.51:80/', //liu本地环境
    //线上环境
    Host_Gong: 'https://api.sport.jinxikids.com/',


    appId: 'wx8ad42d97e065305b',
};
