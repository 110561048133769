import axios from 'axios';
import configs from './config';
import {getToken, getToken2} from './cache';
import {Toast} from 'vant';
import qs from "qs";

axios.defaults.baseURL = configs.Host_Gong;
axios.defaults.headers = {
    'Content-Type': 'application/json;charset=UTF-8'
};
// 设置超时时间
axios.defaults.timeout = 600000;
// 允许携带cookie
axios.defaults.withCredentials = false;

/**
 * 请求拦截器，请求发送之前做些事情
 */
axios.interceptors.request.use(
    (config) => {
        // 这里需要引入第三方模块qs, POST请求时先格式化data数据
        if (config.method.toLocaleUpperCase() === 'POST' && config.baseURL.indexOf("newversion") !== -1) {
            config.data = qs.stringify(config.data)
        }
        config.headers = (config.baseURL.indexOf("newversion") === -1) ?
            {
                'Content-Type': 'application/json;charset=UTF-8'
            } :
            {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }

        const token2 = getToken2();
        if (token2) config.headers['token'] = token2;

        const token = getToken();
        if (token) config.headers.Authorization = token;
        return config;
    },
    (error) => {
        // 此处应为弹窗显示具体错误信息
        // 引入第三方UI框架
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (response.status === 200) {
            return response;
        } else {
            if (res.message !== '' && res.message !== null) {
                Toast(res.message);
            }
        }
        return Promise.reject(res.message);
    },
    (error) => {
        Toast('网络连接失败！');
        return Promise.reject(error);
    }
);

export default axios;
